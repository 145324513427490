.login {
    position: absolute;
    background: linear-gradient(168.55deg, #FFDF37 0%, #FF37AF 43.75%, #742ECE 100%);

}

.login a:link {
    color: #fffb6e;
}
.login a:visited {
    color: #fffb6e;
}

.login .main-logo {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 20px;    

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.login .baccus {
    position: absolute;
    top: 225px;
    margin: auto;
    
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 34px;

    color: #fff;    
} 

.login .baccus2 {
    position: absolute;
    top: 270px;
    margin: auto;
    
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;

    color: #fff;    
} 

#email-label {
    color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px none inset;
    transition: background-color 5000s ease-in-out 0s;
}

#root > div > form > div > div.MuiFormControl-root.MuiTextField-root.inputId > div {
    color: #fff;
    border-radius: 29px !important; 
}

.login .inputId {
    position: absolute;
    width: 260px;
    height: 57px;
    top: 320px;
    
    background: rgba(80, 80, 80, 0.3);
    border-radius: 29px;
}

#password-label {
    color: #fff;
}

#root > div > form > div > div.MuiFormControl-root.MuiTextField-root.inputPasswd > div {
    color: #fff;
    border-radius: 29px !important; 
}

.login .inputPasswd {
    position: absolute;
    width: 260px;
    height: 57px;
    top: 390px;
    
    background: rgba(80, 80, 80, 0.3);
    border-radius: 29px;
}

.login .remeber {
    position: absolute;
    height: 18px;
    top: 450px;
}

#root > div > div > div.remeber > div > label {
    color: #f0f0f0 !important;
}

.login .login-Button {
    position: absolute;
    width: 260px;
    height: 58px;
    top:500px;

    border-radius: 29px;
}

.login .login-first {
    position: absolute;
    font-style: normal;
}

.login .login-join {
    position: absolute;
    font-style: normal;

    color: #FAFF00;    
}


.login .login-myId {
    position: absolute;
    font-style: normal;

    color: #FAFF00;    
}

.login .login-myPw {
    position: absolute;
    font-style: normal;

    color: #FAFF00;    
}