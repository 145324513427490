a:-webkit-any-link {
    text-decoration: none;
    cursor: pointer;
}

@media screen and (min-width: 320px) {
    .baccus-card {
        position : relative;

        width: 288px;
        min-width: 288px;
        height: 144px;
    }

    .state-card {
        box-sizing: 'border-box';
        position : relative;

        width: 288px;
    }

    .company-card {
        box-sizing: 'border-box';
        position : relative;

        width: 288px;
    }
}

@media screen and (min-width: 360px) {
    .baccus-card {
        position : relative;

        width: 324px;
        min-width: 324px;
        height: 162px;
    }

    .state-card {
        box-sizing: 'border-box';
        position : relative;

        width: 324px;
    }

    .company-card {
        box-sizing: 'border-box';
        position : relative;

        width: 324px;
    }
}

@media screen and (min-width: 480px) {
    .baccus-card {
        position : relative;

        width: 432px;
        min-width: 432px;
        height: 216px;
    }

    .state-card {
        box-sizing: 'border-box';
        position : relative;

        width: 432px;
    }

    .company-card {
        box-sizing: 'border-box';
        position : relative;

        width: 432px;
    }
}

@media screen and (min-width: 800px) {
    .baccus-card {
        position : relative;

        width: 600px;
        min-width: 600px;
        height: 300px;

        margin: 0 auto;
        text-align: center;
        border-radius: 10px;
    }

    .state-card {
        box-sizing: 'border-box';
        position : relative;

        width: 600px;
    }

    .company-card {
        box-sizing: 'border-box';
        position : relative;

        width: 600px;
    }
}

.circle2 {
    position: absolute;
    width: 200px;
    height: 150px;
    bottom: 0px;
    right: 0px;

    background: #D9D9D9;
    mix-blend-mode: overlay;
    opacity: 0.3;
    border-top-left-radius: 150px;
    filter: blur(3px);
}

.star1 {
    position: absolute;
    width: 200px;
    height: 200px;
    left: -80px;
    top: -100px;
    
    background: #D9D9D9;
    mix-blend-mode: overlay;
    opacity: 0.3;
    border-radius: 50%;
    filter: blur(3px);
}