@media screen and (min-width: 320px) {
    .order-card {
        width:300px !important;
    }

    .order-zone {
        width:320px !important;
    }
}

@media screen and (min-width: 360px) {
    .order-card {
        width: 320px !important;
    }

    .order-zone {
        width:360px !important;
    }
}

@media screen and (min-width: 480px) {
    .order-card {
        width:400px !important;
    }

    .order-zone {
        width:480px !important;
    }
}

@media screen and (min-width: 800px) {
    .order-card {
        width:375px !important;
    }

    .order-zone {
        width:800px !important;
    }
}

@media screen and (min-width: 1000px) {
    .order-card {
        width:400px !important;
    }

    .order-zone {
        width:1000px !important;
    }
}

@media screen and (min-width: 1300px) {
    .order-card {
        width:400px !important;
    }

    .order-zone {
        width:1300px !important;
    }
}

.ShoppingBag-Icon {
    font-size: 32pt;
    color: #FF4D00;
}

.alarm-shipping {
    color: #cfcfcf;
}

.BoxOrBottle {
    width: 80px;
    height: 30px;
    border-color: #cfcfcf;
    outline-color: #cfcfcf;
    padding: 5px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    margin-right: 10px;
    float: right;
}

.BoxOrBottle option {
    width: 80px;
    height: 30px;
    background: #ffffff;
    color: #666666;
    padding: 3px 0;
    border-color: #ffffff;
}

.BoxOrBottle option:disabled{
    color: #efefef;
}

.selectBox .icoArrow {
    position: absolute; 
    top: 0; 
    right: 0; 
    z-index: 1; 
    width: 35px; 
    height: inherit;
    border-left: 2px solid lightcoral;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quantity {
    font-family: Arial, '맑은 고딕';
    font-weight: bold;
    border: 0px;
    border-color: none;
    width:28px;
    height:24px;
    font-size: 12pt;
    float: right;
    clear: both;
    margin-left: 4px;
}

.quantity:focus {
    outline: none;
}

.quanButton {
    border: 1px !important;
    border-color: '#efefef' !important;
}
